import React, { useEffect, useState } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Table, Select, Form, InputNumber } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import useAccountingCode from './AccountingCodeContext';
import ContentCustom from '../../../../components/ContentCustom/ContentCustom';
import PageHeaderCustom from '../../../../components/PageHeader/PageHeader';
import HeaderTable from './HeaderTable';
import HeaderButton from './Buttons/HeaderButton';
import FooterButton from './Buttons/FooterButton';
import useColumns from './Columns';
import formatter from './utils';

const AccountingCode = () => {
  const {
    fetchAccountingCodes,
    fetchSettingCoef,
    accountingCodes,
    settingCoef,
    refresh,
    loading,
    updateInvoice,
    isEdit,
    setIsEdit
  } = useAccountingCode();
  const {
    getMattersAndShapings,
    getProducts,
    getAccessories,
    getLayAndLabor,
    getDeliveryCost,
    getEcoTaxe,
    totalColumn
  } = formatter;
  const { t } = useTranslation();
  const { state } = useLocation();
  const history = useHistory();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [invoiceData, setInvoiceData] = useState([]);
  const columns = useColumns();

  const getTva = (totals, formatData) => {
    const { tva, discountCustomer } = totals;

    if (discountCustomer && discountCustomer > 0) {
      const amountDiscount = Number(
        (totalColumn(formatData, 'totalHT') * (discountCustomer / 100)).toFixed(
          2
        )
      );

      const amountDiscountTva = Number(
        (totalColumn(formatData, 'mtTva') * (discountCustomer / 100)).toFixed(2)
      );

      formatData.push({
        type: 'title-type.discount',
        quantity: '-',
        label: t('invoices.accounting-code.message-type.discount', {
          discountCustomer
        }),
        totalHT: amountDiscount,
        mtTva: amountDiscountTva
      });
    }

    if (totals && tva > 0) {
      formatData.push({
        type: 'title-type.tva',
        quantity: '-',
        label: t('invoices.accounting-code.message-type.tva', {
          tva
        }),
        mtTva: totalColumn(formatData, 'mtTva'),
        totalHT: ''
      });
    }
    return formatData;
  };

  const formatAdvInvoice = (values, datas) => {
    let result = [];
    const mtTTC = Number(
      (values?.totalsTTC / ((100 + values?.tva) / 100)).toFixed(2)
    );
    const mtTva = Number((values?.totalsTTC - mtTTC).toFixed(2));
    let type = 'basic-invoice';

    switch (true) {
      case datas.is_advance_payment:
        type = 'advance-payment';
        break;
      case datas.is_credit_note:
        type = 'credit-note';
        break;

      default:
        type = 'basic-invoice';
        break;
    }

    result.push({
      type: `title-type.${type}`,
      quantity: '-',
      label: t(`invoices.accounting-code.message-type.${type}`, {
        number: datas?.quote?.number
      }),
      mtTva,
      totalHT: mtTTC
    });

    result = getTva(values, result);

    result = result.map((obj) => {
      const add = { id: uuidv4(), salesType: '', code: null };
      return { ...obj, ...add };
    });

    return result;
  };

  const renderLabel = (isAdv, isBasic) => {
    let result = 'invoices.accounting-code.message-type.basic-invoice';

    switch (true) {
      case isAdv && !isBasic:
        result = 'invoices.accounting-code.message-type.adv';
        break;
      case !isAdv && !isBasic:
        result = 'invoices.accounting-code.message-type.credit-note';
        break;

      default:
        break;
    }

    return result;
  };

  const getAdvPayments = (current, payments, totals) => {
    const result = [];
    const calculateHT = (ttc, tva) => {
      return ttc / (1 + tva / 100);
    };

    if (payments && payments.length > 0) {
      payments.forEach(
        ({
          amount,
          advance_payment_number,
          advance_payment,
          is_basic_invoice
        }) => {
          result.push({
            type: 'title-type.adv',
            is_basic_invoice,
            advance_payment,
            quantity: '-',
            label: t(renderLabel(advance_payment, is_basic_invoice), {
              number: advance_payment_number
            }),
            mtTva: amount - calculateHT(amount, totals?.tva),
            totalHT: calculateHT(amount, totals?.tva)
          });
        }
      );
    }

    return [...current, ...result];
  };

  const formatAccountingData = (datas, coefs) => {
    const mattersAndShapings = getMattersAndShapings(datas, coefs);
    const products = getProducts(datas);
    const layAndLabor = getLayAndLabor(datas);
    const deliveryCost = getDeliveryCost(datas);
    const ecoTaxe = getEcoTaxe(datas);
    const accessories = getAccessories(datas);
    const { totals, is_advance_payment, is_credit_note, payments } = datas;

    if (totals && (is_credit_note || is_advance_payment)) {
      return setInvoiceData(formatAdvInvoice(totals, datas));
    }

    let format = [
      mattersAndShapings,
      products,
      layAndLabor,
      deliveryCost,
      ecoTaxe,
      accessories
    ].flat();

    format = getTva(totals, format);

    format = getAdvPayments(format, payments, totals);

    format = format.map((obj) => {
      const add = { id: uuidv4(), salesType: '', code: null };
      return { ...obj, ...add };
    });
    const filteredData = format.filter(
      (item) =>
        !item.is_basic_invoice &&
        !(item.advance_payment === false && item.is_basic_invoice === false)
    );

    return setInvoiceData(filteredData);
  };

  useEffect(() => {
    fetchAccountingCodes();
    fetchSettingCoef();
  }, []);

  const goBack = () => {
    setIsEdit(false);
    return history.goBack();
  };

  const handleSubmit = () => {
    updateInvoice(id, invoiceData);
    goBack();
  };

  const handleSalesTypeChange = (value, idRecord) => {
    const { label, code } = value;
    const data = [...invoiceData];
    const newData = data.map((item) => {
      if (idRecord === item.id) return { ...item, salesType: label, code };
      return item;
    });
    setInvoiceData(newData);
  };

  const columnInvoice = (data, options) => {
    const result = [
      ...data,
      {
        title: t('invoices.accounting-code.column.salesType'),
        dataIndex: 'salesType',
        key: 'salesType',
        sorter: (a, b) => a.salesType.length - b.salesType.length,
        render: (_, record) => {
          return (
            <Select
              value={record.salesType}
              key={record.id}
              style={{ width: 200 }}
              onChange={(__, value) => handleSalesTypeChange(value, record.id)}
              options={options}
              disabled={!isEdit}
            />
          );
        }
      },
      {
        title: t('invoices.accounting-code.column.code'),
        dataIndex: 'code',
        key: 'code',
        sorter: (a, b) => a.code - b.code,
        render: (code) => (
          <InputNumber disabled style={{ width: 200 }} value={code} />
        )
      }
    ];

    return result;
  };
  useEffect(() => {
    if (settingCoef.length > 0 && !state.is_accounting_code) {
      formatAccountingData(state, settingCoef);
      setIsEdit(true);
    } else if (state.is_accounting_code) {
      setInvoiceData(state.accounting_code);

      setIsEdit(false);
    }
  }, [refresh, settingCoef]);

  return (
    <Form form={form} onFinish={handleSubmit}>
      <PageHeaderCustom
        title={t('invoices.accounting-code.header')}
        extra={
          <HeaderButton
            id={id}
            isDisplay={state.is_accounting_code}
            setIsEdit={setIsEdit}
            isEdit={isEdit}
          />
        }
      />
      <ContentCustom>
        <HeaderTable
          datas={invoiceData}
          setDatas={setInvoiceData}
          isEdit={isEdit}
          options={accountingCodes}
          form={form}
          required={!state.is_accounting_code}
        />
        <Table
          style={{ marginTop: 24 }}
          dataSource={invoiceData}
          loading={loading}
          pagination={false}
          columns={columnInvoice(columns, accountingCodes)}
          scroll={{ x: 600 }}
          footer={() => isEdit && <FooterButton cancel={goBack} />}
        />
      </ContentCustom>
    </Form>
  );
};

export default AccountingCode;
