import moment from 'moment';
import { totalCalculator } from '../headers';

export const formatter = (t, columns, datas) => {
  const result = [];

  const headers = columns.map(({ label }) => t(`${label}`));

  result.push(headers);

  if (datas)
    datas.forEach((data) => {
      const rawData = [
        data.buy_site.label || 'N/R',
        moment(data.created_at).format('DD/MM/YYYY') || 'N/R',
        data?.supplier?.name || 'N/R',
        `${data?.matter?.label || ''} - ${data?.matter?.color || ''}`,
        data?.batch_number || 'N/R',
        data?.matter?.completions || 'N/R',
        data?.thickness || 'N/R'
      ];

      const filteredSizes = (data?.sizes || []).filter(
        (size) => !size.exit_date
      );

      const sizeGroups = filteredSizes.reduce(
        (acc, { length, width, type }) => {
          const key = `${length}-${width}`;
          if (!acc[key]) {
            acc[key] = {
              length,
              width,
              count: 0,
              type
            };
          }
          acc[key].count += 1;
          return acc;
        },
        {}
      );

      const groupedSizesArray = Object.values(sizeGroups).map(
        ({ length, width, count, type }) => ({
          width,
          length,
          count,
          type
        })
      );

      groupedSizesArray.forEach((sizeGroup) => {
        const { matter, buying_price } = data;
        const { width, length, count } = sizeGroup;
        const surfaces = (width / 1000) * (length / 1000);
        const sellPrice = Number((buying_price * surfaces * count).toFixed(2));
        const value_in_euro = totalCalculator(buying_price, sizeGroup, matter);
        result.push([
          ...rawData,
          width,
          length,
          count,
          buying_price || 'N/R',
          sellPrice,
          matter?.deliveryCost || 'N/R',
          value_in_euro
        ]);
      });
    });

  return result;
};
