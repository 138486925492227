import PropTypes from 'prop-types';
import React, { useState, useEffect, createContext, useContext } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useHistory, useLocation } from 'react-router-dom';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

export const DealsContext = createContext({});

export const DealsContextProvider = ({ children }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [visible, setVisible] = useState(false);
  const [deals, setDeals] = useState([]);
  const [deal, setDeal] = useState(null);
  const [kanbanCols, setKanbanCols] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState({});
  const [sites, setSites] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [matters, setMatters] = useState([]);
  const [stocks, setStocks] = useState([]);
  const [labels, setLabels] = useState([]);
  const [status, setStatus] = useState([]);
  const [filters, setFilters] = useState('');
  const [searchValue, setSearchValue] = useState();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const searchResource = (value) => {
    if (value) {
      history.push({
        pathname,
        search: `?k=${value}`
      });
    } else {
      history.push({
        pathname
      });
    }
  };

  const fetchData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/trellos-cards?populate=orders,orders.infos,worksheet,buildingSite,buildingSite.crm,orders.quotation,quotation.quotlines.elements.element,color,quotation,commercial_site,production_sites,lay_site,files,orders.section_1.elements.color, orders.section_2.elements.color,  orders.section_3.elements.color,   orders.section_4.elements.color,    orders.section_5.elements.color,     orders.infos.commercial_site,      orders.infos.customer${filters}`
      });

      data.forEach(({ orders }, index) => {
        if (orders) {
          Object.keys(orders).forEach((key) => {
            const toDelete = orders;
            if (
              toDelete &&
              key.startsWith('section_') &&
              !orders[key].project_type
            ) {
              delete toDelete[key];
            }
          });
        } else delete data[index];
      });

      setDeals(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading({});
  };

  const handleDuplicate = async (data) => {
    const body = {
      label: data.label,
      orders: data.orders,
      quotation: data.quotation,
      site: data.site,
      status: data.status
    };
    try {
      await dispatchAPI('POST', {
        url: `/trellos-cards`,
        body
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    window.location.reload();
  };

  const delDeal = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `trellos-cards/${id}` });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const updateDeal = async (id, body) => {
    try {
      await dispatchAPI('PATCH', { url: `/trellos-cards/${id}`, body });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const fetchKanbanColumns = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/trellos' });
      setKanbanCols(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEmployees = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/employees' });
      setEmployees(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSites = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/sites' });
      setSites(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getStocks = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/pieces' });
      const pieces = data.map(({ catalogue, sizes }) => {
        return {
          id: catalogue,
          sizes: (sizes[0].height / 1000) * (sizes[0].width / 1000)
        };
      });
      setStocks(pieces);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getMatters = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/matters' });
      setMatters(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getLabels = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/cards-labels' });
      setLabels(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getStatus = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/trellos' });
      setStatus(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    getSites();
    fetchKanbanColumns();
    getEmployees();
    getMatters();
    getLabels();
    getStatus();
    getStocks();
  }, []);

  useEffect(() => {
    fetchData();
  }, [forceRefresh, filters, searchValue]);

  return (
    <DndProvider backend={HTML5Backend}>
      <DealsContext.Provider
        value={{
          fetchData,
          delDeal,
          updateDeal,
          deals,
          deal,
          setDeal,
          searchResource,
          searchValue,
          visible,
          setVisible,
          kanbanCols,
          fetchKanbanColumns,
          employees,
          isLoading,
          setIsLoading,
          forceRefresh,
          setForceRefresh,
          sites,
          handleDuplicate,
          isModalVisible,
          handleOk,
          showModal,
          isModalVisible2,
          setIsModalVisible2,
          matters,
          stocks,
          labels,
          status,
          setFilters,
          filters,
          setSearchValue
        }}
      >
        {children}
      </DealsContext.Provider>
    </DndProvider>
  );
};

DealsContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(DealsContext);
