import PropTypes from 'prop-types';
import React, {
  useState,
  useEffect,
  createContext,
  useCallback,
  useContext
} from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useHistory, useLocation } from 'react-router-dom';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

export const DealsContext = createContext({});

export const DealsContextProvider = ({ children }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [visible, setVisible] = useState(false);
  const [deals, setDeals] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [sites, setSites] = useState([]);
  const [deal, setDeal] = useState(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [searchValue, setSearchValue] = useState(params.get('k'));
  const [kanbanCols, setKanbanCols] = useState([]);
  const [dataRefresh, setDataRefresh] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState({});
  const [projectTypes, setProjectTypes] = useState(null);

  const searchResource = (value) => {
    if (value) {
      history.push({
        pathname,
        search: `?k=${value}`
      });
    } else {
      history.push({
        pathname
      });
    }
  };

  useEffect(() => {
    setSearchValue(params.get('k'));
  }, [params]);

  const fetchData = useCallback(async () => {
    const searchUrl = searchValue
      ? `filter={"$text": {"$search":"${searchValue}"}}&`
      : null;
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/deals?${searchUrl ||
          ''}&populate=site,quotes,professionals,customers,prescribers, quotes.quote`
      });
      setDeals(data);
      const AllprojectTypes = [];
      if (data.length > 0) {
        data.forEach((row) => {
          if (row.quotes) {
            row.quotes.map(({ quote }) => {
              if (quote?.section_1) {
                AllprojectTypes.push(quote.section_1.project_type);
              }
              if (quote?.section_2) {
                AllprojectTypes.push(quote.section_2.project_type);
              }
              if (quote?.section_3) {
                AllprojectTypes.push(quote.section_3.project_type);
              }
              if (quote?.section_4) {
                AllprojectTypes.push(quote.section_4.project_type);
              }
              if (quote?.section_5) {
                AllprojectTypes.push(quote.section_5.project_type);
              }
            });
          }
        });
      }
      setProjectTypes(Array.from(new Set(AllprojectTypes)).sort());
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading({});
  }, [searchValue, visible]);

  const fetchSites = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/sites`
      });
      setSites(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  }, []);

  const fetchCustomers = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/customers`
      });
      setCustomers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  }, []);

  const delDeal = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `deals/${id}` });
      await fetchData();
      setDataRefresh(!dataRefresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const updateDeal = async (id, body) => {
    try {
      await dispatchAPI('PATCH', { url: `/deals/${id}`, body });
      await fetchData();
      setDataRefresh(!dataRefresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const fetchKanbanColumns = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/kanbans' });
      setKanbanCols(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEmployees = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/employees' });
      setEmployees(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const handleFilter = async (filters) => {
    let filter = '';

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filter += `${key}=${filters[key]}&`;
      }
    });
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/deals?${filter}populate=site,quotes,professionals,customers,prescribers, quotes.quote`
      });
      setDeals(data);
    } catch (err) {
      if (err.response) message(err.response.status);
    }
  };

  const fetch = useCallback(async () => {
    await fetchKanbanColumns();
    await getEmployees();
    await fetchSites();
    await fetchCustomers();
  }, [isLoading]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <DndProvider backend={HTML5Backend}>
      <DealsContext.Provider
        value={{
          fetchData,
          delDeal,
          updateDeal,
          deals,
          deal,
          setDeal,
          searchResource,
          searchValue,
          visible,
          setVisible,
          kanbanCols,
          fetchKanbanColumns,
          dataRefresh,
          setDataRefresh,
          employees,
          isLoading,
          setIsLoading,
          handleFilter,
          sites,
          customers,
          projectTypes
        }}
      >
        {children}
      </DealsContext.Provider>
    </DndProvider>
  );
};

DealsContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(DealsContext);
