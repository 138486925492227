import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';
import { ExportSheet } from 'react-xlsx-sheet';
import * as XLSX from 'xlsx';
import { formatter } from './formatter';

export const ExportStocks = ({
  exportDatas,
  resourceName,
  customFormatter,
  columns
}) => {
  const [exports, setExports] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (customFormatter) setExports(customFormatter());
    else setExports(formatter(t, columns, exportDatas));
  }, [exportDatas]);

  const ExportXLSXButton = ({ exportsheet }) => (
    <Button
      type="primary"
      block
      icon={<DownloadOutlined />}
      onClick={exportsheet}
    >
      {t(`buttons.action.export-${resourceName}`)}
    </Button>
  );

  ExportXLSXButton.propTypes = {
    exportsheet: PropTypes.func
  };

  ExportXLSXButton.defaultProps = {
    exportsheet: () => {}
  };

  return (
    <>
      <ExportSheet
        fileName={t(`exports.${resourceName}`)}
        dataSource={exports}
        dataType="Array-of-Arrays"
        isDOMElement={false}
        xlsx={XLSX}
      >
        <ExportXLSXButton />
      </ExportSheet>
    </>
  );
};

ExportStocks.propTypes = {
  exportDatas: PropTypes.func.isRequired,
  resourceName: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customFormatter: PropTypes.func
};

ExportStocks.defaultProps = {
  customFormatter: null
};
