export const totalCalculator = (buying_price, sizes, matter) => {
  const total =
    (sizes.length / 1000) *
    (sizes.width / 1000) *
    (buying_price + matter?.deliveryCost);

  const result = total * sizes.count;

  return Number(result.toFixed(2));
};

export const headers = [
  {
    label: 'pieces.column.buy_site',
    key: 'pieces.column.buy_site'
  },
  {
    label: 'pieces.column.created_at',
    key: 'pieces.column.created_at'
  },
  {
    label: 'pieces.column.supplier',
    key: 'pieces.column.supplier'
  },
  {
    label: 'pieces.column.matter',
    key: 'pieces.column.matter'
  },
  {
    label: 'pieces.column.batch_number',
    key: 'pieces.column.batch_number'
  },
  {
    label: 'pieces.column.completions',
    key: 'pieces.column.completions'
  },
  {
    label: 'pieces.column.thickness',
    key: 'pieces.column.thickness'
  },
  {
    label: 'pieces.column.length',
    key: 'pieces.column.length'
  },
  {
    label: 'pieces.column.width',
    key: 'pieces.column.width'
  },
  {
    label: 'pieces.column.sizes',
    key: 'pieces.column.sizes'
  },
  {
    label: 'pieces.column.buying_price',
    key: 'pieces.column.buying_price'
  },
  {
    label: 'pieces.column.value',
    key: 'pieces.column.value'
  },
  {
    label: 'pieces.column.transport',
    key: 'pieces.column.transport'
  },
  {
    label: 'pieces.column.value_in_euro',
    key: 'pieces.column.value_in_euro'
  }
];
